import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 查询素材列表
 * @param params
 */
export function getMeterialList(params) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/material/list',
    method: 'get',
    params,
  });
}

/**
 * 删除素材
 * @param id 要删除的实例id
 */
export function deleteMeterial(id) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/material/delete?id=' + id,
    method: 'delete',
  });
}

/**
 * 查询可素材详情
 * @param id 要查询的实例id
 */
// export function getVisualizationDetail(id) {
//   return request({
//     url: envInfo.bgApp.visualizationPath + '/material/detail?id=' + id,
//     method: 'get',
//   });
// }

/**
 * 修改素材
 * @param id 要修改的实例id
 */
export function modifyMeterial(params) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/material/update?id=' + params.id,
    method: 'put',
    data: params,
  });
}

/**
 * 新增可视化实例
 * @param
 */
export function addMeterial(data) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/material/create',
    method: 'post',
    data,
  });
}
