var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props_setting_container" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "外观", name: "props" } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-position": "top",
                    "label-width": "100%",
                    model: _vm.formLabelAlign,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentActiveShape,
                          expression: "currentActiveShape",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "X(px)" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.formItemChange(
                                          "x",
                                          _vm.formLabelAlign.x
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formLabelAlign.x,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formLabelAlign, "x", $$v)
                                      },
                                      expression: "formLabelAlign.x",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Y(px)" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.formItemChange(
                                          "y",
                                          _vm.formLabelAlign.y
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formLabelAlign.y,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formLabelAlign, "y", $$v)
                                      },
                                      expression: "formLabelAlign.y",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "宽(px)" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.formItemChange(
                                          "width",
                                          _vm.formLabelAlign.width
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formLabelAlign.width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formLabelAlign,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression: "formLabelAlign.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "高(px)" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.formItemChange(
                                          "height",
                                          _vm.formLabelAlign.height
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formLabelAlign.height,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formLabelAlign,
                                          "height",
                                          $$v
                                        )
                                      },
                                      expression: "formLabelAlign.height",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "旋转(°)" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.formItemChange(
                                          "rotation",
                                          _vm.formLabelAlign.rotation
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formLabelAlign.rotation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formLabelAlign,
                                          "rotation",
                                          $$v
                                        )
                                      },
                                      expression: "formLabelAlign.rotation",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.currentActiveShape,
                          expression: "!currentActiveShape",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "宽" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.formItemChange(
                                          "width",
                                          _vm.formLabelAlign.width
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formLabelAlign.width,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formLabelAlign,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression: "formLabelAlign.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "高" } },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      "controls-position": "right",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.formItemChange(
                                          "height",
                                          _vm.formLabelAlign.height
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formLabelAlign.height,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formLabelAlign,
                                          "height",
                                          $$v
                                        )
                                      },
                                      expression: "formLabelAlign.height",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "背景图片" } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.formLabelAlign.backgroundImage,
                                      expression:
                                        "formLabelAlign.backgroundImage",
                                    },
                                  ],
                                  staticClass: "background_img_container",
                                },
                                [
                                  _c("div", { staticClass: "hover_tips" }, [
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: { click: _vm.deleteImage },
                                    }),
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.formLabelAlign.backgroundImage,
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "#",
                                    "show-file-list": false,
                                    "http-request": _vm.imageUploadHandler,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { type: "primary" } },
                                    [_vm._v("上传背景图片")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-tab-pane", { attrs: { label: "数据", name: "second" } }, [
            _vm._v(" 数据配置 "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }