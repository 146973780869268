var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.graphLoading,
          expression: "graphLoading",
        },
      ],
      staticClass: "visualization_detail_comp",
    },
    [
      _c("topBar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.previewFlag,
            expression: "!previewFlag",
          },
        ],
        attrs: { scale: _vm.scale },
        on: { graphEvent: _vm.graphEvent },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.previewFlag,
              expression: "previewFlag",
            },
          ],
          staticClass: "preview_bar",
        },
        [
          _c("div", [_vm._v("预览")]),
          _c(
            "div",
            {
              staticClass: "close_icon",
              on: {
                click: function ($event) {
                  _vm.previewFlag = false
                },
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "Esc键退出全屏预览",
                    placement: "bottom",
                  },
                },
                [_c("i", { staticClass: "el-icon-close" })]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "main_container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.previewFlag,
                expression: "!previewFlag",
              },
            ],
            staticClass: "menu_container",
          },
          [
            _c("asideMenu", {
              on: { setCurrentDragItem: _vm.setCurrentDragItem },
            }),
          ],
          1
        ),
        _c("div", {
          staticClass: "stage_container",
          attrs: { id: "stage_container" },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.previewFlag,
                expression: "!previewFlag",
              },
            ],
            staticClass: "props_container",
          },
          [
            _c("detailProps", {
              attrs: {
                currentActiveShape: _vm.currentActiveShape,
                currentActiveProps: _vm.currentActiveProps,
              },
              on: { setBackground: _vm.setBackground },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.contextMenuShow,
              expression: "contextMenuShow",
            },
          ],
          attrs: { id: "context_container" },
        },
        [_vm._m(0)]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.saveDialog.saveDialogShow,
            title: _vm.saveDialog.title,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "chartForm",
              attrs: {
                model: _vm.saveDialog,
                rules: _vm.scenceRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "场景名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请输入场景名称",
                    },
                    model: {
                      value: _vm.saveDialog.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog, "name", $$v)
                      },
                      expression: "saveDialog.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "场景描述" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "3",
                      "auto-complete": "off",
                      placeholder: "请输入场景描述",
                    },
                    model: {
                      value: _vm.saveDialog.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog, "description", $$v)
                      },
                      expression: "saveDialog.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelSave } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitSave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("button", { attrs: { id: "up-button" } }, [_vm._v("上移一层")]),
      _c("button", { attrs: { id: "down-button" } }, [_vm._v("下移一层")]),
      _c("button", { attrs: { id: "copy-button" } }, [_vm._v("复制")]),
      _c("button", { attrs: { id: "delete-button" } }, [_vm._v("删除")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }