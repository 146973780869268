import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 查询可视化列表
 * @param params
 */
export function getVisualizationList(params) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/view/list',
    method: 'get',
    params,
  });
}

/**
 * 删除可视化实例
 * @param id 要删除的实例id
 */
export function deleteVisualization(id) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/view/delete?id=' + id,
    method: 'delete',
  });
}

/**
 * 查询可视化实例详情
 * @param id 要查询的实例id
 */
export function getVisualizationDetail(id) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/view/detail?id=' + id,
    method: 'get',
  });
}

/**
 * 修改可视化实例
 * @param id 要修改的实例id
 */
export function modifyVisualization(params) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/view/update?id=' + params.id,
    method: 'put',
    data: params,
  });
}

/**
 * 新增可视化实例
 * @param
 */
export function addVisualization(data) {
  return request({
    url: envInfo.bgApp.visualizationPath + '/view/create',
    method: 'post',
    data,
  });
}
